import React from 'react';

import TronWalletLogo from '../../assets/img/tronwalletlogo.png';
import './TronWalletGuide.scss';

const WEBSTORE_URL = 'https://www.tronwallet.me/';

const logo = (
    <div className='logo'>
        <img src={ TronWalletLogo } alt='TronWallet logo' />
    </div>
);

const openTronWallet = () => {
    window.open(WEBSTORE_URL, '_blank');
};

const TronWalletGuide = props => {
    const {
        installed = false,
        loggedIn = false
    } = props;

    if(!loggedIn || !installed) {
        return (
            <div className='tronWalletGuide' onClick={ openTronWallet }>
                <div className='info'>
                    <h1>Log in Required</h1>
                    <p>
                        Open this link from TronWallet app.
                    </p>
                    <div className="download-call">
                        <a href={WEBSTORE_URL} className="btn-download">
                            <i className="tw-icon icon-triangle-down"></i>Download
                        </a>    
                        <br/><br/>
                        <p>Download Klever app to Use.</p>
                    </div>
                </div>                
            </div>
        );
    }

    return (
        <div className='tronWalletGuide' onClick={ openTronWallet }>
            <div className='info'>
                <h1>Wrong Network</h1>                
                    <h5>This is a BETA Test version. To ensure your funds are safe, please use Klever with Shata Network.</h5>
                    Once installed, return back and refresh the page.                
            </div>
            { logo }
        </div>
    );
    
};

export default TronWalletGuide;
import React from 'react'
import packageJson from '../../../package.json';

import { Footer } from './elements'

export default class GettyFooter extends React.Component {
  render () {
    return (
      <Footer>
        Staking and Swap Test Interface
        <br/>
        Powered by Klever
        <br/>
        v {packageJson.version}
      </Footer>
    )
  }
}

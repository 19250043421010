import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default class ConfirmDialogSlide extends Component {
  render() {
    const { 
        open,
        onConfirm,
        onDismiss,
        currentAPR,
        amountToFreeze,
        minTimeToUnfreeze,
        unfreezeDelay
    } = this.props;
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onDismiss}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">{"Confirm freeze?"}</DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
                You have requested to freeze {amountToFreeze.toLocaleString()} KLV at a interest rate of {currentAPR}% APR. <br/>
                This will freeze your KLV in KleverBank for a minimum of {minTimeToUnfreeze} days.<br/>
                Once you complete the minimal time frozen, you will be able to request unfreeze, which will take {unfreezeDelay} days to be withdrawn.<br/>
                <br/>
                Do you agree with the term and conditions?
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <Button onClick={onDismiss} color="primary">
                Disagree
            </Button>
            <Button onClick={onConfirm} color="primary">
                Agree
            </Button>
            </DialogActions>
        </Dialog>
    );
  }
}

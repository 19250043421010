import styled from 'styled-components'
import { FontSize, Colors } from '../DesignSystem'

export const Footer = styled.div`
  display: flex;
  padding: 10px;
  margin-top: 20px;
  border-top: 1px solid ${Colors.strokeGrey};
  margin-bottom: 10px;
  color: ${Colors.titleLabel};
  justify-content: center;
  font-size: ${FontSize.xsmall};
  text-align: center;
`;
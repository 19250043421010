import React  from 'react';


import TronWalletGuide from '../TronWalletGuide';
import './App.scss';
import '../../assets/fonts/tronwallet-fonts.css';
import '../../assets/fonts/tronwallet-icons.css';
import '../../assets/styles/tronwallet.scss';
//import { Colors, FontSize } from 'components/DesignSystem'

import SwipeableViews from 'react-swipeable-views';

import { StyledTabs, StyledTab, TabPanel} from './elements';

import BankingActions from '../BankingActions';
import SwapActions from '../SwapActions';

class App extends React.Component {
    state = {
        tronWeb: {
            installed: false,
            loggedIn: false,
            testNet: false,
        },
        account: "",
        tabValue: 0,
    }

    handleChange = (event, newValue) => {
        this.setState({
            tabValue: newValue
        });
    };


    componentDidMount() {
        const loadWatcher = setInterval( async () => {
            if(window.tronWeb && window.tronWeb.ready) {
                clearInterval(loadWatcher);
                const installed = !!window.tronWeb;
                const tronWebState = {
                    installed: installed,
                    loggedIn: true
                };

                const token = await window.tronWeb.contract().at(process.env.REACT_APP_CONTRACT_DAPP);
                const oldToken = await window.tronWeb.contract().at(process.env.REACT_APP_OLDTOKEN_SWAP);
                const banking = await window.tronWeb.contract().at(process.env.REACT_APP_CONTRACT_BANK);
                const swap = await window.tronWeb.contract().at(process.env.REACT_APP_CONTRACT_SWAP);
                const decimals = await token.decimals().call();

                this.setState({
                    tronWeb: tronWebState,
                    oldToken,
                    token,
                    banking,
                    swap,
                    decimals,
                    account: window.tronWeb.defaultAddress.base58
                });

                window.tronWeb.on('addressChanged', (addr) =>{
                    const { account } = this.state;
                    // reload address
                    if (account !== addr.base58) {
                        this.setState({
                            account: addr.base58
                        })
                    }
                })
            }
        }, 200)
    }


    render() {

        const {tronWeb: { 
            installed: tronWebInstalled,
            loggedIn: tronWebLoggedIn,
            testNet: tronWebTestNet
        }, account, tabValue, oldToken, token, banking, decimals, swap  } = this.state;
        
        const ready = tronWebInstalled && tronWebLoggedIn && !tronWebTestNet
    
        return (
            <div className="flex-shrink-0">
                <br/><br/><br/>

                <div className="container">
                <div className='header'>
                    <img width="200" src="https://static1.squarespace.com/static/5b746f23f93fd4df93382a39/t/5efb4319b3aacc08e34223dc/1593525018218/Klever_wordmark_horizontal_white.png?format=2500w" />
                    <br/><br/><br/><br/>
                    <h4>Staking Test Interface</h4>
                    <br/><br/>
                </div>                    
                    {ready &&
                        <div >
                            <StyledTabs value={tabValue} onChange={this.handleChange}>
                                <StyledTab label="Staking KLV" />
                                <StyledTab label="Swap TWX to KLV" />
                            </StyledTabs>
                            <SwipeableViews
                                    
                                    axis={'x'}
                                    index={tabValue}
                                >
                                    <TabPanel value={tabValue} index={0}>
                                        <BankingActions
                                            token={token}
                                            banking={banking}
                                            decimals={decimals}
                                            account={account}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>
                                        <SwapActions
                                            oldToken={oldToken}
                                            swap={swap}
                                            decimals={decimals}
                                            account={account}
                                        />
                                    </TabPanel>
                            </SwipeableViews>

                        </div>
                    }

                    { !tronWebInstalled && <TronWalletGuide /> }
                    { tronWebInstalled && !tronWebLoggedIn && <TronWalletGuide installed /> }
                    { tronWebInstalled && tronWebLoggedIn && tronWebTestNet && <TronWalletGuide installed loggedIn /> }
                </div>
            </div>
        );
    }
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import { Wrapper } from './components/Utils'
import GettyFooter from './components/GettyFooter';

const app = (
    <Wrapper className="container">
        <App />
        <GettyFooter/>
    </Wrapper>
)


ReactDOM.render(app, document.getElementById('root'));

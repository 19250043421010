import React  from 'react';
import { Row } from '../Utils'
import moment from 'moment'
import ConfirmDialog from './confirmDialog';

class BankingActions extends React.Component {
    state = {
        currentAPR: 0,
        amountToFreeze: 0,
        frozenAccount: {
            amount: 0,
            daysFrozen: 0,
            interest: 0,
            realizedInterest: 0,
            lastClaim: 0,
            availableClaim: 0,
            canClaim: false,
            canUnfreeze: false,
            canWithdraw: false,
            APR: 0,
            unfrozen: 0,
            available: 'No'
        },
        showConfirmFreeze: false,
        totalFrozen:0 ,
        totalMinted: 0,
        minTimeToUnfreeze: 0,
        unfreezeDelay: 0,
        account: '',
        balance: 0,
        results: {
            frozenBalanceOf: ""
        }
    };

    

    componentDidMount(){
        const { account } = this.props;
        this.setState({
            account
        }, ()=>this.tokenSync().then(()=>
            this.sync = setInterval(this.tokenSync, 10000)
        ));

    }
    componentWillUnmount(){
        if (this.sync) {
            clearInterval(this.sync);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { account } = this.props;
        const { account: accountNext } = nextProps;
        if (accountNext && accountNext !== account) {
          this.setState({ account: accountNext }, this.tokenSync);
        }
    }

    tokenSync = async () => {
        const { token, banking, decimals } = this.props;
        if(!token || !banking || !decimals) return;
        const { account, frozenAccount } = this.state;

        const r = await Promise.all([
            banking.currentAPR().call(),
            banking.claimDelay().call(),
            banking.totalFrozen().call(),
            banking.totalMinted().call(),
            banking.minTimeToUnfreeze().call(),
            banking.unfreezeDelay().call()            
        ])
        .then( r => r)
        .catch(e => {
            console.log("Retrying: ", e)
            return null;
        });
        if (!r) {
            // force token resync on error
            this.tokenSync()
            return
        }
        const [currentAPR, claimDelay, totalFrozen, totalMinted, minTimeToUnfreeze, unfreezeDelay] = r;

        let balance = 0;

        if (window.tronWeb.isAddress(account)){
            const frozen = await banking.statusOf(account)
                .call()
                .then( f => f)
                .catch(e => {
                    console.log("Retrying account: ", e)
                    return null;
                });
            if (!frozen) {
                 // force token resync on error
                this.tokenSync()
                return
            }
            let TSFrozen = 0;
            if (frozen.frozenDate.lt(2000000000) && frozen.frozenDate.gt(0) ){
                TSFrozen = moment(new Date(frozen.frozenDate.toNumber() * 1000)).fromNow();
            }
            let TS = 0;
            if (frozen.availableOn.lt(2000000000) && frozen.availableOn.gt(0) ){
                const withdrawTime = new Date(frozen.availableOn.toNumber() * 1000)
                TS = moment(withdrawTime).fromNow();
                frozenAccount.canWithdraw = withdrawTime !== 0 && withdrawTime <= Date.now();
            }
            let TSUnfreeze = 0;
            if (frozen.unfreezeAvailableOn.lt(2000000000) && frozen.unfreezeAvailableOn.gt(0)){
                const unfreezeTime = new Date(frozen.unfreezeAvailableOn.toNumber() * 1000)
                TSUnfreeze = moment(unfreezeTime).fromNow();
                frozenAccount.canUnfreeze = unfreezeTime !== 0 && unfreezeTime <= Date.now();
            }
            let lastClaim = 0;
            let TSClaim = 0
            if (frozen.lastClaim.lt(2000000000) && frozen.lastClaim.gt(0)){
                lastClaim = moment(new Date(frozen.lastClaim.toNumber() * 1000)).fromNow();
                let nextClaim = new Date((frozen.lastClaim.toNumber()+claimDelay.toNumber()) * 1000)
                frozenAccount.canClaim = nextClaim !== 0 && nextClaim <= Date.now();
                TSClaim = moment(nextClaim).fromNow();
            }


            frozenAccount.frozen = frozen.frozen.toNumber() / (10 ** decimals);
            frozenAccount.interest = frozen.pendingInterest.toNumber() / (10 ** decimals);
            frozenAccount.realizedInterest = frozen.realizedInterest.toNumber() / (10 ** decimals);
            frozenAccount.daysFrozen = (TSFrozen===0)?'-':TSFrozen;
            frozenAccount.unfrozen = frozen.unfrozen.toNumber() / (10 ** decimals);
            frozenAccount.available = (TS===0)?'No':TS;
            frozenAccount.availableUnfreeze = (TSUnfreeze===0)?'No':TSUnfreeze;
            frozenAccount.lastClaim = (lastClaim===0)?'-':lastClaim;
            frozenAccount.availableClaim = (TSClaim===0)?'-':TSClaim;
            frozenAccount.APR = frozen.APR / 100;

            balance = await token.balanceOf(account).call();
            balance = balance.toNumber() / (10 ** decimals);
        }
        this.setState({
            currentAPR: currentAPR / 100,
            balance,
            frozenAccount,
            totalFrozen: totalFrozen.toNumber() / (10 ** decimals),
            totalMinted: totalMinted.toNumber() / (10 ** decimals),
            minTimeToUnfreeze: minTimeToUnfreeze.toNumber() / 86400,
            unfreezeDelay: unfreezeDelay.toNumber() / 86400,
        });

    }

    onChange = e =>{
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onFreeze = async () => {
        
        const { token, banking, decimals } = this.props;
        const { amountToFreeze, results } = this.state;
        this.setState({
            showConfirmFreeze: false,
            amountToFreeze: 0
        })



        const totalToFreeze = amountToFreeze * (10 ** decimals)
        const approval = await token.approve(process.env.REACT_APP_CONTRACT_BANK, totalToFreeze).send();

        
        const result = await banking.freeze(totalToFreeze).send();
        this.setState({
            results: {
                ...results,
                freeze: result
            }
        }, this.tokenSync);
    }

    onUnfreeze = async () => {
        const { banking } = this.props;
        const { results } = this.state;
        const result = await banking.unfreeze().send();
        this.setState({
            results: {
                ...results,
                action: result
            }
        }, this.tokenSync);
    }

    onClaim = async () => {
        const { banking } = this.props;
        const { results } = this.state;
        const result = await banking.claim().send();
        this.setState({
            results: {
                ...results,
                action: result
            }
        }, this.tokenSync);
    }

    onWithdraw = async () => {
        const { banking } = this.props;
        const { results } = this.state;
        const result = await banking.withdraw().send();
        this.setState({
            results: {
                ...results,
                action: result
            }
        }, this.tokenSync);
    }

    render() {
        const {
            account,
            balance,
            frozenAccount,
            currentAPR,
            amountToFreeze,
            results,
            totalFrozen,
            totalMinted,
            showConfirmFreeze,
            minTimeToUnfreeze,
            unfreezeDelay
        } = this.state;

        const {
            canClaim,
            canUnfreeze,
            canWithdraw
        } = frozenAccount;

        return (
            <div>
                <ConfirmDialog 
                    open={showConfirmFreeze}
                    onConfirm={this.onFreeze}
                    onDismiss={()=>this.setState({
                        showConfirmFreeze: false
                    })}
                    currentAPR={currentAPR}
                    amountToFreeze={amountToFreeze}
                    minTimeToUnfreeze={minTimeToUnfreeze}
                    unfreezeDelay={unfreezeDelay}
                />
                <br/>
                <div className="form-group" >
                    <h5>Current APR: {currentAPR}%</h5>
                </div>

                <div className="form-group" >
                    <Row>
                        <fieldset>
                            <legend>Account</legend>
                            <div className="input-wrapper">
                                <input placeholder='Wallet address'
                                name="account"
                                value={account}
                                onChange={ ()=>{} }/>
                            </div>
                        </fieldset>
                    </Row>
                    <Row justify="left">
                    {results.account}
                    </Row>
                </div>

                <div className="form-group" >
                    <Row justify="left">
                        <ul>
                            <li>Balance: {balance}</li>
                            <li>Frozen Balance: {frozenAccount.frozen}</li>
                            <li>Frozen since: {frozenAccount.daysFrozen}</li>
                            <li>APR: {frozenAccount.APR}%</li>
                            <li>Unfrozen Balance: {frozenAccount.unfrozen}</li>
                            <li>Interest: {frozenAccount.interest}</li>
                            <li>Realized Interest: {frozenAccount.realizedInterest}</li>
                            <li>Available Unfreeze: {frozenAccount.availableUnfreeze}</li>
                            <li>Available Withdraw: {frozenAccount.available}</li>
                            <li>Last Claim: {frozenAccount.lastClaim}</li>
                            <li>Next Claim On: {frozenAccount.availableClaim}</li>
                        </ul>
                    </Row>
                    <Row>
                        <button
                            className={ !canUnfreeze?'btn-tw disabled':'btn-tw'  }
                            onClick={ this.onUnfreeze }
                            disabled={!canUnfreeze}
                        >
                            Unfreeze
                        </button>
                        <button
                            className={ !canClaim?'btn-tw disabled':'btn-tw'  }
                            onClick={ this.onClaim }
                            disabled={!canClaim}
                        >
                            Claim
                        </button>
                        <button
                            className={ !canWithdraw?'btn-tw disabled':'btn-tw' }
                            onClick={ this.onWithdraw }
                            disabled={!canWithdraw}
                        >
                            Withdraw
                        </button>
                    </Row>
                    <Row justify="left" top="10px">
                    {results.action}
                    </Row>
                </div>


                <div className="form-group" >
                    <Row>
                        <fieldset>
                            <legend>Freeze</legend>
                            <div className="input-wrapper">
                                <input placeholder='Amount to freeze'
                                name="amountToFreeze"
                                value={amountToFreeze}
                                onChange={ this.onChange }/>
                            </div>
                        </fieldset>
                        <button
                            className={ 'btn-tw' }
                            onClick={ ()=>{this.setState({
                                showConfirmFreeze: true
                            })} }
                        >
                            Confirm
                        </button>
                    </Row>
                    <Row justify="left">
                    {results.freeze}
                    </Row>
                </div>

                <div className="form-group" >
                    <Row justify="left">
                        <ul>
                            <li>Total Frozen: {totalFrozen.toLocaleString()} KLV</li>
                            <li>Total Minted: {totalMinted.toLocaleString()} KLV</li>
                        </ul>
                    </Row>
                </div>
            </div>
        )
    }
}

export default BankingActions;
